<template>
  <div class="d-flex flex-column flex-root">
    <div class="d-flex flex-column flex-lg-row flex-column-fluid bg-white">
      <onboarding-aside></onboarding-aside>
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <transition name="fade-in-up">
            <router-view />
          </transition>
        </div>
        <!-- <div
          class="d-flex justify-content-lg-start justify-content-center align-items-end py-4 py-lg-0"
        >
          <a href="#" class="text-primary font-weight-bolder font-size-h5"
            >Terms & Conditions</a
          >
          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"
            >Privacy Policy</a
          >
        </div> -->
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/login/login.scss";
</style>

<script>
import { mapGetters } from "vuex";
//import Loader from "@/view/content/pilot-loader.vue";
import OnboardingAside from "@/view/layout/aside/onboarding-aside.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

export default {
  name: "onboarding",
  components: {
    //"pilot-loader": Loader,
    "onboarding-aside": OnboardingAside
  },
  beforeMount() {
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
  },
  mounted() {
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }

    setTimeout(() => {
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["isAuthenticated", "currentUser"]),

    backgroundImage() {
      return process.env.BASE_URL + "media/svg/illustrations/analytics.svg";
    },

    loaderLogo() {
      return process.env.BASE_URL + "media/logos/pilot-logo.png";
    }
  },
  methods: {}
};
</script>
