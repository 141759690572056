<template>
  <div
    class="onboarding-aside d-flex flex-column flex-row-auto"
    style="background-color: #5145cd"
  >
    <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
      <a href="#" class="text-center mb-10">
        <img src="media/logos/pilot-logo.png" class="max-h-70px" alt="" />
      </a>
      <h3
        class="font-weight-bolder text-center font-size-h5 font-size-h3-lg"
        style="color: #ffffff"
      >
        The Most Advanced Cloud ERP<br />
        For Health, Safety & Environment.
      </h3>
    </div>
    <div
      class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    ></div>
  </div>
</template>
<script>
export default {
  name: "OnboardingAside",
  mounted() {},
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/svg/illustrations/analytics.svg";
    }
  }
};
</script>
